import { action } from 'mobx'
import moment from 'moment'
import { CotacaoCloud, StatusCotacaoCloud } from 'shared/common/models/cotacaoCloud'
import { FornecedorCotacaoCloud } from 'shared/common/models/fornecedorCotacaoCloud'
import { compradorStore } from 'store/CompradorStore'
import authStore from 'store/authStore'
import { cotacaoCompradorStore } from 'store/cotacaoCompradorStore'
import { firestore } from '../config/initFirebase'

export interface IStatusQuoteFilter {
  status: StatusCotacaoCloud
  isChecked: boolean
  statusName: string
  statusQuoteFiltered: boolean
}
export class CotacaoCompradorService {
  @action
  public async getCotacaoComprador() {
    const email = authStore.loggedUser.email
    const idCompany = compradorStore.comprador.idCompany

    const result = await firestore
      .collection('cotacoes')
      .where('comprador.email', '==', email)
      .where('idCompany', '==', idCompany)
      .orderBy('createdAt', 'desc')
      .get()

    if (!result.empty) {
      cotacaoCompradorStore.cotacaoComprador = result.docs.map(
        (doc) => doc.data() as CotacaoCloud,
      )
      await this.getCotacaoFornecedores()
    }
  }

  public async getCotacaoFornecedores() {
    for await (const cotacaoComprador of cotacaoCompradorStore.cotacaoComprador) {
      const result = await firestore
        .collection('fornecedoresCotacoes')
        .where('idCotacao', '==', cotacaoComprador.id)
        .where('idCompany', '==', cotacaoComprador.idCompany)
        .where('status', 'in', [StatusCotacaoCloud.Aberta, StatusCotacaoCloud.Encerrada, StatusCotacaoCloud.EmAprovacao, StatusCotacaoCloud.PendenteEncerramento])
        .get()

      if (!result.empty) {
        cotacaoComprador.fornecedoresCotacao = result.docs.map(
          (doc) => doc.data() as FornecedorCotacaoCloud,
        )
      }
    }
  }

  @action
  public async getCotacaoCompradorFiltered() {
    const email = authStore.loggedUser.email
    const idCompany = compradorStore.comprador.idCompany

    const selectedStatusQuotedFilter =
      cotacaoCompradorStore.statusQuoteFilter.reduce(
        (accumulator: string[], statusQuote: IStatusQuoteFilter) => {
          if (statusQuote.isChecked) {
            statusQuote.statusQuoteFiltered = true
            accumulator.push(statusQuote.status.toString())
          } else {
            statusQuote.statusQuoteFiltered = false
          }
          return accumulator
        },
        [],
      )

    if (cotacaoCompradorStore.initialDate) {
      cotacaoCompradorStore.isFiltered.initialDateFiltered = true;
    }

    if (cotacaoCompradorStore.finalDate) {
      cotacaoCompradorStore.isFiltered.finalDateFiltered = true;
    }

    if (
      !cotacaoCompradorStore.selectedSubsidiary.length &&
      !cotacaoCompradorStore.quoteNumber &&
      !selectedStatusQuotedFilter.length &&
      !cotacaoCompradorStore.initialDate &&
      !cotacaoCompradorStore.finalDate
    ) {
      this.clearFilters()
      return await this.getCotacaoComprador()
    }

    let query = firestore
      .collection('cotacoes')
      .where('comprador.email', '==', email)
      .where('idCompany', '==', idCompany)

    if (cotacaoCompradorStore.selectedSubsidiary.length) {
      query = query.where(
        'filial',
        'in',
        cotacaoCompradorStore.selectedSubsidiary.map(
          (subsidiary) => subsidiary.nome,
        ),
      )
      cotacaoCompradorStore.isFiltered.selectedSubsidiaryFiltered = true
    }

    if (cotacaoCompradorStore.quoteNumber) {
      query = query.where(
        'codigo',
        '==',
        Number(cotacaoCompradorStore.quoteNumber),
      )
      cotacaoCompradorStore.isFiltered.quoteNumberFiltered = true
    }

    const result = await query.orderBy('createdAt', 'desc').get()

    if (!result.empty) {
      cotacaoCompradorStore.cotacaoComprador = result.docs.map(
        (doc) => doc.data() as CotacaoCloud,
      )

      cotacaoCompradorStore.cotacaoComprador = this.filterStatus(
        cotacaoCompradorStore.cotacaoComprador,
        selectedStatusQuotedFilter,
      )

      cotacaoCompradorStore.cotacaoComprador = this.filterDate(
        cotacaoCompradorStore.cotacaoComprador,
      )

      await this.getCotacaoFornecedores()
    } else {
      cotacaoCompradorStore.cotacaoComprador = []
    }
  }

  public filterStatus(
    cotacaoComprador: CotacaoCloud[],
    selectedStatusQuotedFilter: string[],
  ): CotacaoCloud[] {
    if (selectedStatusQuotedFilter.length) {
      return cotacaoComprador.filter((cotacaoComprador) =>
        selectedStatusQuotedFilter.includes(cotacaoComprador.status.toString()),
      )
    }

    return cotacaoComprador
  }

  public filterDate(cotacaoComprador: CotacaoCloud[]): CotacaoCloud[] {
    if (cotacaoCompradorStore.initialDate) {
      cotacaoCompradorStore.isFiltered.initialDateFiltered = true
      cotacaoComprador = cotacaoComprador.filter(
        (cotacaoComprador) =>
          (
            cotacaoComprador.dataLimite as firebase.default.firestore.Timestamp
          ).toDate() >
          moment(cotacaoCompradorStore.initialDate.toString())
            .subtract('1', 'second')
            .toDate(),
      )
    }

    if (cotacaoCompradorStore.finalDate) {
      cotacaoCompradorStore.isFiltered.finalDateFiltered = true
      cotacaoComprador = cotacaoComprador.filter(
        (cotacaoComprador) =>
          (
            cotacaoComprador.dataLimite as firebase.default.firestore.Timestamp
          ).toDate() <
          moment(cotacaoCompradorStore.finalDate.toString())
            .add('1', 'day')
            .toDate(),
      )
    }

    return cotacaoComprador
  }

  private clearFilters() {
    cotacaoCompradorStore.selectedSubsidiary = []
    cotacaoCompradorStore.quoteNumber = null
    cotacaoCompradorStore.statusQuoteFilter = [
      {
        status: StatusCotacaoCloud.Aberta,
        isChecked: false,
        statusName: 'Aberta',
        statusQuoteFiltered: false,
      },
      {
        status: StatusCotacaoCloud.Encerrada,
        isChecked: false,
        statusName: 'Encerrada',
        statusQuoteFiltered: false,
      },
    ]
    cotacaoCompradorStore.initialDate = ''
    cotacaoCompradorStore.finalDate = ''
    cotacaoCompradorStore.isFiltered = {
      selectedSubsidiaryFiltered: false,
      quoteNumberFiltered: false,
      initialDateFiltered: false,
      finalDateFiltered: false,
    }
  }
}

export const cotacaoCompradorService = new CotacaoCompradorService()
