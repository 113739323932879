import { firestore } from 'config/initFirebase'
import { action } from 'mobx'
import { cotacaoStore } from 'store/cotacaoStore'

import { CondicaoPagamentoCloud } from '../common/models/condicaoPagamentoCloud'
import { CotacaoCloud } from '../common/models/cotacaoCloud'
import { EnderecoCotacaoCloud } from '../common/models/enderecoCotacaoCloud'
import { ItemCotacaoCloud } from '../common/models/itemCotacaoCloud'
import { ItemDetalheCotacaoCloud } from '../common/models/itemDetalheCotacaoCloud'

export class CotacaoService {
  @action
  public async getCotacao(id: string) {
    const cot = (await firestore.doc(`/cotacoes/${id}`).get()).data()
    cotacaoStore.cotacao = cot as CotacaoCloud
  }

  @action
  public async getSetItens(id: string, idCompany: string) {
    const result = await firestore
      .collection('itensCotacoes')
      .where('idCompany', '==', idCompany)
      .where('idCotacao', '==', id)
      .get()
    if (!result.empty) {
      cotacaoStore.itens = result.docs.map(
        (doc) => doc.data() as ItemCotacaoCloud,
      )
    }
  }

  @action
  public async getItens(id: string, idCompany: string) {
    const result = await firestore
      .collection('itensCotacoes')
      .where('idCompany', '==', idCompany)
      .where('idCotacao', '==', id)
      .get()

    return result.docs.map((doc) => doc.data() as ItemCotacaoCloud)
  }

  public async getitemDetalhes(id: string, idCompany: string) {
    const result = await firestore
      .collection('itensDetalhesCotacoes')
      .where('idCompany', '==', idCompany)
      .where('idCotacao', '==', id)
      .get()
    const detalhes = result.docs.map(
      (doc) => doc.data() as ItemDetalheCotacaoCloud,
    )

    cotacaoStore.itensDetalhe = detalhes
  }

  @action
  public async getEnderecoEntrega(id: string, idCompany: string) {
    cotacaoStore.enderecoCotacao = (
      await firestore
        .collection('enderecosCotacoes')
        .where('idCompany', '==', idCompany)
        .where('idCotacao', '==', id)
        .get()
    ).docs.map((doc) => doc.data() as EnderecoCotacaoCloud)
  }

  public async getCondicaoPagamento(codigoLista: number, idCompany: string) {
    const result = await firestore
      .collection('condicoesPagamento')
      .where('idCompany', '==', idCompany)
      .where('codigoLista', '==', codigoLista)
      .get()
    const condicoes = result.docs.map(
      (doc) => doc.data() as CondicaoPagamentoCloud,
    )
    cotacaoStore.condicaoPagamento = condicoes
  }

  @action
  public async getDadosCotacao(id: string, idCompany: string) {
    await Promise.all([
      this.getCotacao(id),
      this.getSetItens(id, idCompany),
      this.getEnderecoEntrega(id, idCompany),
      this.getitemDetalhes(id, idCompany),
    ])
    await this.getCondicaoPagamento(
      cotacaoStore.cotacao.codigoListaCondicaoPagamento,
      idCompany,
    )
  }
}

export const cotacaoService = new CotacaoService()
